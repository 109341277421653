import { render, staticRenderFns } from "./Adjustment.vue?vue&type=template&id=48ff9286&scoped=true&"
import script from "./Adjustment.vue?vue&type=script&lang=js&"
export * from "./Adjustment.vue?vue&type=script&lang=js&"
import style0 from "./Adjustment.vue?vue&type=style&index=0&id=48ff9286&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48ff9286",
  null
  
)

export default component.exports