<template>
    <div class="Adjustment full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <navigation-left-small noBackButton/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex">
                    <v-col cols="6" class="pa-0 pr-4">
                        <v-row class="d-flex align-center">
                            <h2 class="pa-3 pb-2">{{ $t('adjCal.heightTemp') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.basket') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ adjustment.height.basket }}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastAdjustment') }}</p>
                            </v-col>
                            <v-col class="col-8 ">
                                <p class="readOnly container">
                                    {{ adjustment.height.dateTime }}
                                    <span class="bullet" :class="{'successful': adjustment.height.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/adjustment-height" class="flex-grow-1 mr-6" :event="checkUserRights('adjustment') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('adjustment')}">{{ $t('adjCal.adjustHeightTemp') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="pa-0 pl-4">
                        <v-row class="d-flex align-center ">
                            <h2 class="pa-3 pb-2">{{ $t('adjCal.basketPosition') }}</h2>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.basket') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly">{{ adjustment.basketPosition.tubeCount }} {{$t('general.tubes')}}</p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <v-col class="col-4">
                                <p>{{ $t('adjCal.lastAdjustment') }}</p>
                            </v-col>
                            <v-col class="col-8">
                                <p class="readOnly container">
                                    {{ adjustment.basketPosition.dateTime }}
                                    <span class="bullet" :class="{'successful': adjustment.basketPosition.status}"></span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center">
                            <router-link to="/adjustment-position" class="flex-grow-1 mr-6" :event="checkUserRights('adjustment') ? 'click' : ''">
                                <div class="ma-3 content-btn" :class="{'disabled': !checkUserRights('adjustment')}">{{ $t('adjCal.adjustBasketPosition') }}</div>
                            </router-link>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToAdjCal"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button button-function="reports" @footerButtonClick="goToAdjReports"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftSmall from '@/components/NavigationLeftSmall.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Adjustment',
    components: {
        NavigationLeftSmall,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
        }
    },
    computed: {
        ...mapState([
            'adjustment',
            'activeStationId'
        ]),
    },
    methods:{
        goToAdjCal(){
            this.$router.push('adj-cal');
        },
        goToAdjReports(){
            this.$router.push('adjustment-reports');
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('postAxiosSetter', ['/disi/calibration/adjust/latest', {id: this.activeStationId}, 'adjustment']);
        this.$store.commit('resetProgress');

        this.$watch('$store.state.activeStationId', function() {
            this.$store.dispatch('postAxiosSetter', ['/disi/calibration/adjust/latest', {id: this.activeStationId}, 'adjustment']);
        });
    }
}
</script>
<style scoped lang="scss">
.content-btn{
    max-height: 52px;
}
h2{
    width: 100%;
    font-size: 1rem;
}
.container{
    position: relative;
}
.bullet{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    &::before{
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $disiRed;
    }
    &.successful::before{
        background-color: $disiIrisblue;
    }
}
</style>